import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { useUserContext } from "./context/userContext";
import SamlAuth from "./components/samlAuth";
const App = () => {
  const { user, loading, error } = useUserContext();

  return (
    <div className="App">
      {error && <p className="error">{error}</p>}
      {loading ? (
        <h2>Loading...</h2>
      ) : (
        <>
          {" "}
          {user ? (
            <Routes>
              <Route path="/" element={<Home />} />
            </Routes>
          ) : (
            <SamlAuth />
          )}{" "}
        </>
      )}
    </div>
  );
};

export default App;
