import React, { useState } from "react";
import { useUserContext } from "../context/userContext";
import "../index.css"
import "../assets/styles/index.css"
const SamlAuth = () => {
  const { signInWithSaml,checkUserSession } = useUserContext();
  return (
    <div className="container">
      <button onClick={signInWithSaml}> Sign in via OVO Jumpcloud </button>
      {checkUserSession()}
    </div>
    
  );
};

export default SamlAuth;