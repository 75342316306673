import React from 'react';
import { RedocStandalone } from 'redoc';
import V1 from '../../specs/v1.yaml';

const Home = () => (
  <RedocStandalone
    specUrl={V1}
    options={{
      theme: {
        colors: {
          primary: {
            main: '#6014c4',
          },
          success: {
            main: '#6014c4',
          },
        },
        rightPanel: {
          backgroundColor: '#6014c4',
        },
        logo: {
          gutter: '12px',
        },
        typography: {
          fontFamily: 'Inter, sans-serif',
          headings: {
            fontWeight: '700',
            fontFamily: 'Inter, sans-serif',
          },
        },
        sidebar: {
          width: '240px',
        },
        layout: {
          showDarkRightPanel: true,
        },
      },
    }}
  />
);

export default Home;
