import { createContext, useContext, useState } from "react";
import {
  onAuthStateChanged,
  signOut,
  signInWithPopup,
  SAMLAuthProvider,
} from "firebase/auth";
import { auth } from "../firebase";

export const UserContext = createContext({});

export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useState(() => {
    setLoading(true);
    const unsubscribe = onAuthStateChanged(auth, (res) => {
      if (res) {
        setUser(res);
      } else {
        setUser(null);
      }
      setError("");
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const signInWithSaml = () => {
    setLoading(true);
    setError("");
    const provider = new SAMLAuthProvider("saml.ovojumpcloud");
    // Method 1
    signInWithPopup(auth, provider)
      .then((res) => console.log(res))
      .catch((err) => {
        console.log(err);
        setError(err.code);
      })
      .finally(() => setLoading(false));

    // Method 2
    // signInWithRedirect(auth, provider);
    // const authInfo = getAuth();
    // getRedirectResult(authInfo)
    //   .then((result) => {
    //     // User is signed in.
    //     console.log(result)
    //     // Provider data available using getAdditionalUserInfo()
    //   })
    //   .catch((err) => {
    //     setError(err.code)
    //     // Handle error.
    //   })
    //   .finally(() => setLoading(false))
  };
  const checkUserSession = () => {
    /// Log out user after expiry
    // const auth = admin.auth();
    auth.onAuthStateChanged((user) => {
      let sessionTimeout = null;
      if (user === null) {
        // User is logged out.
        // Clear the session timeout.
        sessionTimeout && clearTimeout(sessionTimeout);
        sessionTimeout = null;
      } else {
        // User is logged in.
        // Fetch the decoded ID token and create a session timeout which signs the user out.
        user.getIdTokenResult().then((idTokenResult) => {
          // Make sure all the times are in milliseconds!
          const authTime = idTokenResult.claims.auth_time * 1000;
          // Set timeout to 60 mins - milliseconds * seconds * minutes
          const sessionDuration = 1000 * 60 * 60;
          const millisecondsUntilExpiration =
            sessionDuration - (Date.now() - authTime);
          sessionTimeout = setTimeout(
            () => auth.signOut(),
            millisecondsUntilExpiration
          );
        });
      }
    });
  };

  const logoutUser = () => {
    signOut(auth);
  };

  const contextValue = {
    user,
    loading,
    error,
    logoutUser,
    signInWithSaml,
    checkUserSession
  };
  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
