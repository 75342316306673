// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCZXpUI9ajjqIOMt6AxcGiQOuiJtApKBBg",
  authDomain: "ovo-stg-bisnap-2g4s.firebaseapp.com",
  projectId: "ovo-stg-bisnap-2g4s",
  storageBucket: "ovo-stg-bisnap-2g4s.appspot.com",
  messagingSenderId: "718753005442",
  appId: "1:718753005442:web:5469c0ee4c073eb002999b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
